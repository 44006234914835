import React from 'react'
import Footer from '../Footer';
import data from '../../images/data.jpeg';

const Data = () => {
  return (
    <div className='relative z-0 h-40 sm:h-64'>
    <img className='h-full w-full object-cover' src={data} alt="data"/>

    <div className='absolute inset-0 bg-black opacity-50'></div>

    <div className='absolute inset-0'>
      <div className='container mx-auto px-4 sm:px-0 text-secondary h-full w-full flex flex-col items-center justify-center sm:justify-end text-2xl sm:text-5xl'>
        <div className='sm:mb-8 text-center'>
            Data Engineering and Automation
        </div>
      </div>
    </div>

    <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
      <div className='mt-2 text-gray-700'>
          - Daily Reports Automation <br/>
      </div>
    </div>
    
    <Footer/>
  </div>
  )
}

export default Data;