import React from 'react'
import Footer from '../Footer';
import cloudImage from '../../images/cloud.jpg'

const CloudService = () => {
  return (
    <div className='relative z-0 h-40 sm:h-64'>
    <img className='h-full w-full object-cover' src={cloudImage} alt="cloudimage"/>

    <div className='absolute inset-0 bg-black opacity-50'></div>

    <div className='absolute inset-0'>
      <div className='container mx-auto px-4 sm:px-0 text-secondary h-full w-full flex flex-col items-center justify-center sm:justify-end text-2xl sm:text-5xl'>
        <div className='sm:mb-8 text-center'>
          Cloud Services
        </div>
      </div>
    </div>

    <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
      <div className='mt-2 ml-4 text-gray-700'>
          - AWS cloud support individual or company base <br/>
          - Cloud end-to-end services <br/>
          - Cloud Architecture Design and Implementation <br/> <br/> <br/> <br/>
      </div>
    </div>
   
    <Footer/>
  </div>
  )
}

export default CloudService;
