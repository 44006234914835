import React from 'react'

const PageNotFound = () => {
  return (
      <div className='h-screen text-7xl font-bold flex justify-center item-center sm:w-1/2 mt-20 '>
        <h1 className='text-primary'>404</h1>
        <h2>Page Not Found</h2>
      </div>
  )
}

export default PageNotFound;