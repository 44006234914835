import React from 'react'

import hero from '../images/hero.jpg';
import cloud from '../images/cloud.jpg';
import appdev from '../images/appdev.jpg';
import digital from '../images/digital.jpg';
import erp from '../images/erp.jpeg';
import sales from '../images/sales.jpeg';
import data from '../images/data.jpeg';
import webthree from '../images/webthree.jpeg';
import ai from '../images/ai.webp';
import construction from '../images/construction.jpg';

import Footer from '../components/Footer';


const Home = () => {
    return (
        <div>
            <div className='relative z-0 hero-height'>

                <img src={hero} alt="hero" className='block w-full h-full object-cover' />

                <div className='absolute inset-0 opacity-50'></div>

                <div className='px-4 ml-1 sm:px-0 absolute top-7 inset-0 text-secondary'>
                    <div className='container mx-auto h-full flex flex-col justify-center'>
                        <div className='text-5xl sm:w-1/2 mt-1'>Welcome to</div>
                        <div className='text-6xl font-bold sm:w-1/2 mt-1'>Joshinfotech</div>

                        {/* <div className='mt-1 sm:w-1/2 hidden'>
                            We are JoshInfotech, a small but motivated company specializing in Web, app and cloud technologies.
                            We believe passionately in great bargains and excellent service which is why we commit ourselves to giving you the best of
                            both.<br /><br />

                            If you’re looking for something new, you’re in the right place. We strive to be industrious and innovative, offering our
                            customers something they want, putting their desires at the top of our priority list.
                        </div> */}

                        <div className='mt-8 text-primary'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 h-10 animate-bounce">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid ml-2 mt-5 sm:grid-cols-5 gap-2 sm:gap-12'>

                {/*==========CLOUD========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={cloud} alt="some_image" />
                </div>

                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Cloud</div>
                        <h3 className='mt-0 leading-tight text-2xl font-bold mx-auto my-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/cloudservice'>Cloud & DevOps Services</Link> */}
                            {/* <Link className='hover:text-primary font-bold transition-colors duration-300'>Cloud & DevOps Services</Link> */}
                            Cloud & DevOps Services
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300'>Read More</Link> */}
                </div>

                {/*=========APP========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={appdev} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Web & App Development</div>
                        <h3 className='mt-0 leading-tight text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/* <Link className='hover:text-primary font-bold transition-colors duration-300'>Web & App Development</Link> */}
                            Web & App Development
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/appdevelopment'>Read More</Link> */}
                </div>

                {/*==========DIGITAL========*/}
                {/* <div className='sm:col-span-3 h-64 sm:h-400px'>
                    <img className='w-full h-full object-cover' src={digital} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Digital Marketing</div>
                        <h3 className='mt-10 leading-tight text-3xl font-bold mx-auto px-4 sm:px-0 py-8 sm:py-12'> */}
                {/* <Link className='hover:text-primary font-bold transition-colors duration-300'>Digital Marketing</Link> */}
                {/* Digital Marketing */}
                {/* </h3>
                    </div>*/}
                {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/digital'>Read More</Link> */}
                {/*</div> */}


                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={digital} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Digital Marketing</div>
                        <h3 className='mt-0 leading-tight text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/* <Link className='hover:text-primary font-bold transition-colors duration-300'>Digital Marketing</Link> */}
                            Digital Marketing
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/digital'>Read More</Link> */}
                </div>

                {/*==========ERP========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={erp} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>ERP</div>
                        <h3 className='mt-0 leading-tight text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/* <Link className='hover:text-primary font-bold transition-colors duration-300'>Enterprise Resource Planning</Link> */}
                            Enterprise Resource Planning
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/erp'>Read More</Link> */}
                </div>

                {/*==========IT SALES========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={sales} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>IT Sales</div>
                        <h3 className='mt-0 leading-tight text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/sales'>IT Sales and Marketing</Link> */}
                            IT Sales and Marketing
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/sales'>Read More</Link> */}
                </div>

                {/*==========DATA ENGINEERING========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={data} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Data Engineering & Automation</div>
                        <h3 className='mt-0 leading-tight text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/*<Link className='hover:text-primary font-bold transition-colors duration-300'>Data Engineering & Automation</Link>*/}
                            Data Engineering & Automation
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/data'>Read More</Link> */}
                </div>

                {/*==========Web3========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={webthree} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Web 3 and Blockchain Technology</div>
                        <h3 className='mt-0 leading-tight text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/*<Link className='hover:text-primary font-bold transition-colors duration-300'>Web 3 and Blockchain Technology</Link>*/}
                            Web 3 and Blockchain Technology
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/web>Read More</Link> */}
                </div>

                {/*==========AI and ML========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={ai} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Artificial Intelligence and Machine Learning</div>
                        <h3 className='mt-0 leading-tight  text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0 mb-7'>
                            {/*<Link className='hover:text-primary font-bold transition-colors duration-300'>Artificial Intelligence and Machine Learning</Link>*/}
                            Artificial Intelligence and Machine Learning
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/ai'>Read More</Link> */}
                </div>


                {/*==========Construction and Ineterior Design========*/}
                <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
                    <img className='w-full h-full object-cover' src={construction} alt="some_image" />
                </div>
                <div className='sm:col-span-2 flex flex-col justify-between'>
                    <div>
                        <div className='text-base text-gray-500'>Construction and Interior Design</div>
                        <h3 className='mt-0 leading-tight  text-2xl font-bold mx-auto px-0 sm:px-0 py-1 sm:py-0'>
                            {/*<Link className='hover:text-primary font-bold transition-colors duration-300'>Artificial Intelligence and Machine Learning</Link>*/}
                            Construction and Interior Design
                        </h3>
                    </div>
                    {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/ai'>Read More</Link> */}
                </div>
            </div>
            <div className='mt-8 sm:mt-12'>

            </div>
            <Footer />
        </div>
    )
}

export default Home;
