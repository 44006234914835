import React from 'react'
import { Link } from "react-router-dom";
//import Service from '../components/Service';
import Footer from '../components/Footer';

import cloud from '../images/cloud.jpg';
import appdev from '../images/appdev.jpg';
import digital from '../images/digital.jpg';
import erp from '../images/erp.jpeg';
import sales from '../images/sales.jpeg';
import data from '../images/data.jpeg';
import webthree from '../images/webthree.jpeg';
import ai from '../images/ai.webp';
import construction from '../images/construction.jpg';
import pic from '../images/services.jpg';


const Services = () => {
  return (

      <div className='relative z-0 h-40 sm:h-64'>
          <img className='h-full w-full object-cover' src={pic} alt="pic" />
        

          <div className='absolute inset-0 bg-black opacity-50'></div>

          <div className='absolute inset-0'>
            <div className='container mx-auto px-4 sm:px-0 text-secondary h-full w-full flex flex-col items-center justify-center sm:justify-end text-2xl sm:text-5xl'>
              <div className='sm:mb-8 text-center'>
                Services
              </div>
            </div>
          </div>

        <div className='grid mt-4 ml-2 sm:grid-cols-5 gap-2 sm:gap-12'>

          {/*==========CLOUD========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={cloud} alt="some_image" />
          </div>

          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>Cloud</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/cloudservice'>Cloud & DevOps Services</Link> */}
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Cloud & DevOps Services</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - AWS support individual / company base <br />
                  - Cloud end-to-end services <br />
                  - Cloud Architecture Design and Implementation<br />
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300'>Read More</Link> */}
          </div>

          {/*=========APP========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={appdev} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>Web & App Development</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Web & App Development</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - Android and iOS <br />
                  - Support and End-to-End Services <br />
                  - App Design and Architecture <br />
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/appdevelopment'>Read More</Link> */}
          </div>

          {/*==========DIGITAL========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={digital} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>Digital Marketing</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Digital Marketing</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - SEO <br />
                  - Bigger Reach out to target market <br />
                  - Bigger online presence <br />
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/digital'>Read More</Link> */}
          </div>

          {/*==========ERP========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={erp} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>ERP</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Enterprise Resource Planning</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - Customized ERP software <br />
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/erp'>Read More</Link> */}
          </div>

          {/*==========IT SALES========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={sales} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>IT Sales</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300' to='/sales'>IT Sales and Marketing</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - Sales and Markeing of Product <br />
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/sales'>Read More</Link> */}
          </div>

          {/*==========DATA ENGINEERING========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={data} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>Data Engineering & Automation</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Data Engineering & Automation</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - Daily Reports Automation <br />
                </div>
              </div>

            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/data'>Read More</Link> */}
          </div>

          {/*==========Web3========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={webthree} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>Web 3 and Blockchain Technology</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Web 3 and Blockchain Technology</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='mt-2 text-gray-700'>
                  - Coming Soon<br />
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/web>Read More</Link> */}
          </div>

          {/*==========AI and ML========*/}
          <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={ai} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>Artificial Intelligence and Machine Learning</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Artificial Intelligence and Machine Learning</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - Coming Soon<br />
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/ai'>Read More</Link> */}
          </div>

           {/*==========Construction and Interior Design========*/}
           <div className='sm:col-span-3 h-64 sm:h-400px mr-2'>
            <img className='w-full h-full object-cover' src={construction} alt="some_image" />
          </div>
          <div className='sm:col-span-2 flex flex-col justify-between'>
            <div>
              <div className='text-base text-gray-500'>Construction and Interior Design</div>
              <h3 className='mt-1 leading-tight text-2xl'>
                <Link className='hover:text-primary font-bold transition-colors duration-300'>Construction and Interior Design</Link>
              </h3>

              <div className='container mx-auto px-4 sm:px-0 py-8 sm:py-12'>
                <div className='text-lg'>
                  - Interior Designing<br />
                  - Piping and Instrumentation<br />  
                </div>
              </div>
            </div>
            {/* <Link className='hover:text-primary font-bold transition-colors duration-300' to='/ai'>Read More</Link> */}
          </div>
        </div>

        <div className='mt-8 sm:mt-12'>
          {/* <Link className='px-4 py-2 sm:px-6 bg-primary text-secondary rounded focus:outline-none' to="/contact">Contact Us</Link> */}
        </div>

        <Footer />
    </div>
  )
}

export default Services;
