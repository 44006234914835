import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from '../src/components/Home';
import About from '../src/components/About';
import Contact from '../src/components/Contact';
// import Error from '../src/components/Error';
import PageNotFound from '../src/components/PageNotFound';
import Navbar from '../src/components/Navbar';

import Services from '../src/components/Services';
import Cloud from './components/Services/CloudService';
import AppD from './components/Services/AppDevelopment';
import Digital from './components/Services/Digital';
import ERP from './components/Services/ERP';
import Sales from './components/Services/Sales';
import Data from './components/Services/Data';
import Web from './components/Services/Web';
import AI from './components/Services/AI';


function App() {
  return (
    <div className='mx-auto flex flex-col sm:flex-row item-center justify-between'>
        <Navbar /> 
        <div>
          <Routes>
            <Route className="block sm:inline-block" path="/" element={<Home />} />

            <Route className="block sm:inline-block" path="service" element={<Services />} />
            <Route className="block sm:inline-block" path="cloudservice" element={<Cloud />} />
            <Route className="block sm:inline-block" path="appdevelopment" element={<AppD />} />
            <Route className="block sm:inline-block" path="digital" element={<Digital />} />
            <Route className="block sm:inline-block" path="erp" element={<ERP />} />
            <Route className="block sm:inline-block" path="sales" element={<Sales />} />
            <Route className="block sm:inline-block" path="data" element={<Data />} />
            <Route className="block sm:inline-block" path="web" element={<Web />} />
            <Route className="block sm:inline-block" path="ai" element={<AI />} />

            <Route className="block sm:inline-block" path="about" element={<About />} />
            <Route className="block sm:inline-block" path="contact" element={<Contact />} />
            {/* <Route className="block sm:inline-block" path="*" element={<Error />} /> */}
            <Route className="block sm:inline-block" path="*" element={<PageNotFound />} />
          </Routes>
        </div>
    </div>
  );
}

export default App;
