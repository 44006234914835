import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Navbar = () => {

     const [navSize, setnavSize] = useState("5rem");
     const [navColor, setnavColor] = useState("transparent");
     const [navWordColor, setnavWordColor] = useState("#ffffff");

     const listenScrollEvent = () => {
         window.scrollY > 10 ? setnavColor("#ffffff") : setnavColor("transparent");
         window.scrollY > 10 ? setnavSize("5rem") : setnavSize("5rem");
         window.scrollY > 10 ? setnavWordColor("#DC143C") : setnavWordColor("#ffffff");
     };
     useEffect(() => {
         window.addEventListener("scroll", listenScrollEvent);
         return () => {
             window.removeEventListener("scroll", listenScrollEvent);
         };
     }, []);


    return (
        
        <nav id='navbar' style={{ backgroundColor: navColor, height: navSize }} className='px-4 py-4 sm:py-6 bg-white shadow sm:bg-transparent sm:shadow-none sm:fixed w-full z-10'>
            <div className='container mx-auto flex flex-col sm:flex-row items-center justify-between'>

                <div className='w-full flex flex-row items-center justify-between'>
                    <div className='text-5xl font-bold'>
                        <span className='sm:text-primary'>Josh</span>
                        <span className='text-primary'>infotech</span>
                    </div>

                    {/* Toggle buttons for Moblie and Tablet */}
                    {/* <div>
                        <button className='sm:hidden'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>

                        <button className='sm:hidden'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div> */}
                </div>

               {/*                 
                <div className='mt-4 sm:mt-0 w-full sm:space-x-4 sm:text-right sm:block sm:text-secondary' style={{ color: navWordColor, transition: "all 1s" }} >
                    <Link className="block sm:inline-block sm:px-4 py-2 text-primary hover:text-secondary transition-color:duration-300" to="/">Home</Link>
                    <Link className="block sm:inline-block sm:px-4 py-2 text-primary hover:text-secondary transition-color:duration-300" to="/service">Services</Link>
                    <Link className="block sm:inline-block sm:px-4 py-2 text-primary hover:text-secondary transition-color:duration-300" to="/about">About</Link>
                    <Link className="block sm:inline-block sm:px-4 py-2 text-primary hover:text-secondary transition-color:duration-300" to="/contact">Contact</Link>
                </div> */}

                <div className='mt-4 sm:mt-0 w-full sm:space-x-4 sm:text-right sm:block flex flex-row justify-between sm:text-secondary' style={{ color: navWordColor, transition: "all 1s" }} >
                    <Link className="sm:px-4 py-2 text-primary hover:text-primary transition-color:duration-300" to="/">Home</Link>
                    <Link className="sm:px-4 py-2 text-primary hover:text-primary transition-color:duration-300" to="/service">Services</Link>
                    <Link className="sm:px-4 py-2 text-primary hover:text-primary transition-color:duration-300" to="/about">About</Link>
                    <Link className="sm:px-4 py-2 text-primary hover:text-primary transition-color:duration-300" to="/contact">Contact</Link>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
