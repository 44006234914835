import React from 'react'
import Footer from './Footer';
import imagethree from '../images/contact.jpg';

const Contact = () => {
  return (
    <div className='relative z-0 w-screen h-40 sm:h-64'>
      <img className='h-full w-full object-cover' src={imagethree} alt="contact" />

      <div className='absolute inset-0 bg-black opacity-50'></div>

      <div className='absolute inset-0'>
        <div className='container mx-auto px-4 sm:px-0 text-secondary h-full w-full flex flex-col items-center justify-center sm:justify-end text-2xl sm:text-5xl'>
          <div className='sm:mb-8 text-center'>
            Contact
          </div>
        </div>
      </div>

      <div className='container ml-5 mx-auto py-8 sm:py-12'>
        <div className='grid sm:grid-cols-2 sm:gap-4 px-4 sm:px-0'>

          <div>{/* <span className='text-gray-500'>Address: </span> */}Glas Haus Building Level P, Unit P01, 1 Soi Sukhumvit 25, North Klongtoey, Watthana, Bangkok 10110</div>
          <div><span className='text-gray-500 sm:mt-3'>Mr. Ajinkya Joshi: </span>+66 631 299 631 ajinkya.joshi@joshinfotech.com</div>
          {/* <div><span className='text-gray-500'>Email: </span>ajinkya.joshi@joshinfotech.com</div> */}
        </div>

        <div className='mt-8 sm:mt-16 grid sm:grid-cols-1 mr-10 gap-8 sm:gap-12'>
          <div>
            <iframe className='sm:w-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d968.9300030325057!2d100.56281038811106!3d13.73539379939442!
              2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ff35b40c07f%3A0x40d3f0e172469a6f!2sAsia%20HR%20Recruitment%20Agency
              %20(Thailand)%20Co.%2C%20Ltd.!5e0!3m2!1sen!2sth!4v1677401915203!5m2!1sen!2sth"
              height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'>
            </iframe>
          </div>

          {/* <span className='text-red-600 text-sm'>Error</span> */}
          {/* <span className='text-red-600 text-sm'>Error</span> */}
          {/* <span className='text-red-600 text-sm'>Error</span> */}

          {/* <form className='p-8 bg-gray-300 shadow rounded space-y-4'>
            <div>
              <input className='px-4 py-2 w-full border focus:border-primary focus:outline-none rounded bg-gray-200'
                type="text"
                placeholder='name'
                required
              />

             //error message here
            </div>

            <div>
              <input className='px-4 py-2 w-full border focus:border-primary focus:outline-none rounded bg-gray-200'
                type="email"
                placeholder='email'
                required
              />

              //error message
            </div>

            <div>
              <input className='px-4 py-2 w-full border focus:border-primary focus:outline-none rounded bg-gray-200'
                type="number"
                placeholder='number'
                required
              />

            //error message
            </div>

            <div>
              <textarea placeholder='message' row="5"
                className='px-4 py-2 w-full border focus:border-primary focus:outline-none rounded bg-gray-200' required>
              </textarea>
              {/* <span className='text-red-600 text-sm'>Error</span> **
            </div>

            //Error Message**
            <div className='px-4 py-2 bg-red-600 text-red rounded'>Error</div>

            Submitted Message**
            <div className='px-4 py-2 bg-green-600 text-green rounded'>Submitted</div>

            <button className='px-4 py-2 smpx-6 bg-primary text-secondary rounded focus:outline-none'>Submit</button>

          </form> */}

        </div>
      </div>
      <Footer />
    </div>
        )
}

export default Contact;
