import React from 'react'
import Footer from './Footer';
import imagetwo from '../images/aboutus.jpg';

const About = () => {
  return (

    <div>
      <div className='relative z-0 h-40 w-screen sm:h-64'>
        <img className='h-full w-full object-cover' src={imagetwo} alt="about" />

        <div className='absolute inset-0 bg-black opacity-50'></div>

        <div className='absolute inset-0'>
          <div className='container mx-auto px-4 sm:px-0 text-secondary h-full w-full flex flex-col items-center justify-center sm:justify-end text-2xl sm:text-5xl'>
            <div className='sm:mb-8 text-center'>
              About Us
            </div>
          </div>
        </div>
      </div>

      <div className='container ml-2 mx-auto px-4 sm:px-1 py-8 sm:py-12'>
        We are JoshInfotech, a small but motivated company specializing in Web, app and cloud technologies.
        We believe passionately in great bargains and excellent service, which is why we commit ourselves to giving 
        you the best of both.<br /><br />

        If you’re looking for something new, you’re in the right place. We strive to be industrious and innovative, 
        offering our customers something they want, putting their desires at the top of our priority list. Our 
        office is located in Asoke area in heart of Bangkok City and can easily be commutable by BTS or MRT.
        <br /><br />
      </div>

      <Footer />
    </div>
  )
}

export default About;
