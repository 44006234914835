import React from 'react'

const Footer = () => {
  return (
    <footer className='text-center py-6 sm:py-12 bg-black text-secondary text-base flex flex-col'>
        <div className='container mx-auto px-4 sm:px-0'>
            <span>Copyright &copy; 2023 All rights reserved</span>

            <div>
                <span>Made with love by </span>

                <span className='ml-1 font-bold'>
                    <span>Josh</span>
                    <span className='text-primary'>infotech</span>
                </span>
            </div>
        </div>
    </footer>
  )
}

export default Footer;
